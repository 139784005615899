import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Controller, useForm } from 'react-hook-form'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import TextInput from '../../components/TextInput'
import { emailPattern } from '../../utils/emailRegExp'
import captionBoxStyling from './captionBoxStyling'
import { useInitiateSoleTraderOnboardingJourney } from '../../custom-hooks/useInitiateSoleTraderOnboardingJourney'
import theme from '../../assets/theme'
import Typography from '@mui/material/Typography'
import { maxLength150 } from '../../utils/validationConstants'
import SoleTraderDetailsForm from './SoleTraderDetailsForm'
import { SoleTraderIdentificationType } from '../../types/enums/SoleTraderIdentificationType'
import SoleTraderIdentification from './SoleTraderIdentification'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import { defaultCountryCode } from '../../consts'
import ErrorText from '../error/FormErrors'
import { EventTypes } from '../../types/enums/TrackEventType'
import { CompanyType } from '../../types/enums/CompanyType'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import PanelWithDefaultButtons from '../../components/PanelWithDefaultButtons'

interface SoleTraderFormParams {
  onboardingJourney: OnboardingJourney
  setDisplayHeaderContent?: (shouldDisplay: boolean) => void
}

export type SoleTraderInformationForm = {
  soleTraderEmail?: string
  soleTraderPhoneNumber?: string
}

const SoleTraderForm = ({ onboardingJourney, setDisplayHeaderContent }: SoleTraderFormParams) => {
  const trackEvent = useTrackEvent()
  onboardingJourney = { ...onboardingJourney, flowType: undefined }
  const form = useForm<SoleTraderInformationForm>({
    mode: 'onChange',
    defaultValues: {
      soleTraderEmail: onboardingJourney.director?.email || '',
      soleTraderPhoneNumber: onboardingJourney.director?.phoneNumber || ''
    }
  })

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = form

  const [isInitiated, setIsInitiated] = useState(false)
  const [initialEmailSet] = useState(!!form.getValues().soleTraderEmail)
  const [initialPhoneNumberSet] = useState(!!form.getValues().soleTraderPhoneNumber)
  const [soleTraderIdentification, setSoleTraderIdentification] = useState(SoleTraderIdentificationType.Email)

  const onSubmit = (formValues: SoleTraderInformationForm) => {
    trackEvent(EventTypes.CompanyInformation.CLICK_COMPANY_SELECTION_CONTINUE, {
      companyType: CompanyType.SoleTrader,
      identificationType: soleTraderIdentification
    })

    if (!isMutationLoading) {
      const directorContact =
        soleTraderIdentification === SoleTraderIdentificationType.Email
          ? { directorEmail: formValues.soleTraderEmail }
          : { directorPhoneNumber: formValues.soleTraderPhoneNumber }

      initiateSoleTraderFlow(directorContact, {
        onSuccess: () => {
          setIsInitiated(true)
        }
      })
    }
  }

  const { mutate: initiateSoleTraderFlow, isLoading: isMutationLoading } = useInitiateSoleTraderOnboardingJourney({})

  if (initialEmailSet || initialPhoneNumberSet || isInitiated) {
    return <SoleTraderDetailsForm />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} id="soletrader-information-form">
      <Box sx={{ marginTop: theme.spacing(32), marginBottom: theme.spacing(22) }}>
        <SoleTraderIdentification
          onSoleTraderIdentificationChange={setSoleTraderIdentification}
          soleTraderIdentification={soleTraderIdentification}
        />
      </Box>

      <Box sx={captionBoxStyling}>
        {soleTraderIdentification === SoleTraderIdentificationType.Email ? (
          <TextInput
            label="Sole trader's email"
            name="soleTraderEmail"
            register={register}
            error={errors.soleTraderEmail}
            validation={{
              required: 'Please enter your email address',
              pattern: {
                value: emailPattern,
                message: 'Please enter a valid email address'
              },
              maxLength: maxLength150
            }}
          />
        ) : (
          <Controller
            control={form.control}
            rules={{
              required: 'Please enter a phone number',
              validate: (value) =>
                !value ||
                matchIsValidTel(value, { onlyCountries: [defaultCountryCode] }) ||
                'Please enter a valid phone number'
            }}
            render={({ field, fieldState: { error } }) => (
              <Box>
                <FormControl fullWidth>
                  <FormLabel htmlFor="soleTraderPhoneNumber">Sole trader's mobile phone number</FormLabel>
                  <MuiTelInput
                    {...field}
                    sx={{ width: '100%' }}
                    data-cy="soleTraderPhoneNumber"
                    defaultCountry={defaultCountryCode}
                    onlyCountries={[defaultCountryCode]}
                    disableDropdown={true}
                    error={!!error}
                  />
                  {error && <ErrorText id={field.name}>{error.message}</ErrorText>}
                </FormControl>
              </Box>
            )}
            name="soleTraderPhoneNumber"
          />
        )}

        <div style={{ marginTop: theme.spacing(16) }}>
          <Typography variant="helperText2" sx={{ color: theme.palette.common.textGrey900 }}>
            By clicking "Continue", you agree to Kriya using your details to assess your eligibility for credit which
            may include soft checks at credit bureaus.
          </Typography>
        </div>

        <PanelWithDefaultButtons
          merchantName={onboardingJourney.merchant.name}
          redirectUrl={onboardingJourney.abandonedUrl}
          isLoading={isMutationLoading}
          isDisabled={!form.formState.isValid || isMutationLoading}
        />
      </Box>
    </form>
  )
}

export default SoleTraderForm
