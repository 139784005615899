import { CompanyType } from '../types/enums/CompanyType'
import { SearchCompanyDetails } from '../types/SearchCompanyDetails'

export const filterByCompanyType = (companyType: CompanyType, data: SearchCompanyDetails[] | undefined) => {
  if (data === undefined) return data

  switch (companyType) {
    case CompanyType.LimitedCompany:
      return data.filter((d) => d.auxiliaryIdentifiers.some((a) => a.isNationalId))
    case CompanyType.GovernmentEntity:
      return data.filter((d) => !d.auxiliaryIdentifiers.some((a) => a.isNationalId))
    case CompanyType.SoleTrader:
      return []
  }
}
