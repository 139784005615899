import React, { createContext, useContext } from 'react'

interface CompanyTypeContextType {
  companyType: string | undefined
  setCompanyType: (value: string) => void
}

export const CompanyTypeContext = createContext<CompanyTypeContextType | undefined>(undefined)

export const useCompanyTypeContext = () => {
  const context = useContext(CompanyTypeContext)
  if (!context) {
    throw new Error('useCompanyTypeContext must be used within a CompanyTypeContext.Provider')
  }
  return context
}
