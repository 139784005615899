import React, { useEffect, useState } from 'react'
import { getSuitableCompanyIdentifier } from '../utils/auxiliaryIdentifierProvider'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { CompanyInformationForm } from '../pages/companyInformationStep/CompanyInformationStep'
import ErrorText from '../pages/error/FormErrors'
import FormLabel from '@mui/material/FormLabel'
import SearchIcon from '@mui/icons-material/Search'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { UseFormReturn } from 'react-hook-form'
import { defaultCountryCode } from '../consts'
import theme from '../assets/theme'
import useDebounce from '../custom-hooks/useDebounce'
import { useFetchCompanyAutocompleteData } from '../custom-hooks/useFetchCompanyAutocompleteData'
import { SearchCompanyDetails } from '../types/SearchCompanyDetails'
import { Address } from '../types/Address'
import Grid from '@mui/material/Grid'
import { CompanyType } from '../types/enums/CompanyType'

type CompanySearchProps = {
  form: UseFormReturn<CompanyInformationForm>
  defaultCompanyValue?: string
  companyType: string
}

export const CompanySearchComponent = ({ form, defaultCompanyValue, companyType }: CompanySearchProps) => {
  const {
    setValue,
    register,
    getValues,
    formState: { errors }
  } = form

  const [autocompleteValue, setAutocompleteValue] = useState<SearchCompanyDetails | null>({
    name: getValues('company.name') ?? '',
    kriyaCompanyIdentifier: getValues('company.kriyaCompanyIdentifier') ?? '',
    auxiliaryIdentifiers: []
  })

  useEffect(() => {
    setAutocompleteValue({
      name: '',
      kriyaCompanyIdentifier: '',
      auxiliaryIdentifiers: []
    })
  }, [companyType])

  const [inputValue, setInputValue] = useState<string>('')
  register('company.name', { required: 'Enter name or organisation number' })

  const countryOfRegistration = defaultCountryCode
  const debouncedSearch = useDebounce(inputValue, 1000)
  const { isLoading, data: searchResults } = useFetchCompanyAutocompleteData(
    debouncedSearch,
    2,
    companyType as CompanyType
  )

  return (
    <>
      <Box
        sx={{
          p: !countryOfRegistration?.includes(defaultCountryCode)
            ? {
                sm: theme.spacing(7, 0, 0),
                xs: theme.spacing(6, 0, 0)
              }
            : {
                sm: theme.spacing(7, 0, 11),
                xs: theme.spacing(7, 0, 10)
              },
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {defaultCompanyValue ? (
          <>
            <Typography variant="inputLabel" sx={{ mb: theme.spacing(2) }}>
              Your organisation
            </Typography>
            <Typography data-cy="company.name" variant="caption">
              {defaultCompanyValue}
            </Typography>
          </>
        ) : (
          <>
            <FormLabel
              htmlFor="company-search-autocomplete"
              sx={{
                typography: 'inputLabel'
              }}
            >
              Your organisation
            </FormLabel>
            <Autocomplete
              id="company-search-autocomplete"
              data-cy="company-search-autocomplete"
              value={autocompleteValue}
              filterOptions={(options) => options}
              isOptionEqualToValue={(option, value) => option.kriyaCompanyIdentifier === value.kriyaCompanyIdentifier}
              freeSolo={true}
              onChange={(_event, value, reason) => {
                if (reason !== 'selectOption') {
                  setAutocompleteValue(null)
                  setValue('company.name', '')
                  setValue('company.kriyaCompanyIdentifier', '')
                  return
                }
                if ((value as SearchCompanyDetails) !== undefined) {
                  const companyValue = value as SearchCompanyDetails
                  setAutocompleteValue(companyValue)
                  setValue('company.kriyaCompanyIdentifier', companyValue.kriyaCompanyIdentifier)
                  setValue('company.name', companyValue.name)
                  form.clearErrors('company')
                }
              }}
              options={searchResults || []}
              loading={isLoading}
              renderInput={(params) => (
                <TextField
                  placeholder="Enter name or registration number"
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: <SearchIcon />,
                    endAdornment: (
                      <>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                  inputProps={{
                    ...params.inputProps,
                    'data-hj-whitelist': true
                  }}
                />
              )}
              getOptionLabel={formatOptionLabel}
              onInputChange={(_event, value, reason) => {
                if (reason === 'reset') {
                  return
                }
                setValue('company.name', '')
                setValue('company.kriyaCompanyIdentifier', '')
                setInputValue(value)
              }}
              renderOption={renderOption}
            />
          </>
        )}
        {errors?.company?.name && errors?.company?.name.type === 'required' && (
          <ErrorText id={'company-search'}>Please enter a full company name or registration number</ErrorText>
        )}
      </Box>
    </>
  )
}

const formatOptionLabel = (option: string | SearchCompanyDetails) => {
  if (typeof option === 'string') return option
  if (option !== undefined) {
    return formatCompanyName(option)
  }
  return option
}

const formatCompanyName = (company?: SearchCompanyDetails | null): string => {
  if (!company) return ''
  const identifier = getSuitableCompanyIdentifier(company?.auxiliaryIdentifiers)
  if (identifier) {
    return `${company.name} - ${identifier}`
  }
  return company.name
}

const formatCompanyAddress = ({ addressLine1, addressLine2, city, region, postCode }: Address): string => {
  const parts = [addressLine1, addressLine2, city, region, postCode]
  return parts.filter(Boolean).join(', ')
}

const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: SearchCompanyDetails) => {
  const name = formatCompanyName(option)
  const address = option.address ? formatCompanyAddress(option.address) : ''

  return (
    <Box component="li" {...props} key={option.kriyaCompanyIdentifier + option.name} data-cy={'company-picker-row'}>
      <Grid container sx={{ alignItems: 'flex-start' }} data-cy={'form-row'}>
        <Grid item md={12}>
          <Typography variant="body2">{name}</Typography>
        </Grid>
        <Grid item md={12}>
          <Typography variant="body1">{address}</Typography>
        </Grid>
      </Grid>
    </Box>
  )
}
