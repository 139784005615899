import React from 'react'
import Box from '@mui/material/Box'
import { maxWidthLargeDevices, minWidthSmallDevices } from '../../../../assets/styles/ContentStyles'
import Logo from '../../../../components/Logo'
import DirectorSoleTraderStartPageContent from './DirectorSoleTraderStartPageContent'
import { OnboardingJourney } from '../../../../types/OnboardingJourney'
import { useUpdateOnboardingJourneyStatus } from '../../../../custom-hooks/useUpdateOnboardingJourneyStatus'
import { OnboardingJourneyStatus } from '../../../../types/enums/OnboardingJourneyStatus'
import { useForm } from 'react-hook-form'
import { useSaveCheckDetails } from '../../../../custom-hooks/useSaveCheckDetails'
import { CheckType } from '../../../../types/enums/CheckType'
import useTrackVirtualPageView from '../../../../custom-hooks/useVirtualPageView'
import { VirtualPage } from '../../../../types/enums/VirtualPage'
import PanelWithDefaultButtons from '../../../../components/PanelWithDefaultButtons'

const captionBoxStyling = {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '32px',
  p: 0
}

export interface DirectorContactForm {
  termsAndConditions?: boolean
}

const DirectorSoleTraderStartPage = (data: OnboardingJourney) => {
  useTrackVirtualPageView(VirtualPage.SOLE_TRADER_ELIGIBLE)
  const { company, riskDecision } = data

  const { mutate: updateJourneyStatus, isLoading: isUpdateJourneyStatusLoading } = useUpdateOnboardingJourneyStatus({
    onboardingJourneyId: data.id
  })

  const { mutateAsync: saveCheckMutationAsync, isLoading: isCheckUpdateInProgress } = useSaveCheckDetails({
    onboardingJourneyId: data.id,
    checkType: CheckType.SignedTerms
  })

  const isMutationLoading = isUpdateJourneyStatusLoading

  const hasCheckOfType = (checkType: CheckType) => (data.checks?.filter((r) => r.type === checkType).length || 0) > 0

  const onSubmit = () => {
    if (!hasCheckOfType(CheckType.SignedTerms)) {
      updateJourneyStatus({ newStatus: OnboardingJourneyStatus.ModularChecksAreInProgress })
    } else {
      void saveCheckMutationAsync({}).then(() => {
        updateJourneyStatus({ newStatus: OnboardingJourneyStatus.ModularChecksAreInProgress })
      })
    }
  }

  const form = useForm<DirectorContactForm>({
    mode: 'onChange'
  })

  return (
    <Box
      sx={{
        maxWidth: { sm: maxWidthLargeDevices },
        minWidth: { xs: minWidthSmallDevices }
      }}
    >
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <form id="director-form" style={{ rowGap: captionBoxStyling.rowGap, display: 'grid' }}>
        <DirectorSoleTraderStartPageContent
          riskDecision={riskDecision}
          company={company}
          form={form}
          hasCheckOfType={hasCheckOfType}
        />
        <Box sx={captionBoxStyling}>
          <PanelWithDefaultButtons
            merchantName={data.merchant.name}
            redirectUrl={data.abandonedUrl}
            isLoading={isMutationLoading || isCheckUpdateInProgress}
            isDisabled={!form.formState.isValid || isMutationLoading || isCheckUpdateInProgress}
            source={data.source}
            onClick={onSubmit}
            sx={{ mt: 0 }}
          />
        </Box>
      </form>
    </Box>
  )
}

export default DirectorSoleTraderStartPage
