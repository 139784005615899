import { Content, ContentGroup } from '../../assets/styles/ContentStyles'
import React, { useEffect, useRef, useState } from 'react'

import Box from '@mui/material/Box'
import { Company } from '../../types/Company'
import { OnboardingJourney } from '../../types/OnboardingJourney'
import { Person } from '../../types/Person'
import Typography from '@mui/material/Typography'
import theme from '../../assets/theme'
import Logo from '../../components/Logo'
import Link from '@mui/material/Link'
import styled from '@emotion/styled'
import { CompanyType } from '../../types/enums/CompanyType'
import { FlowType } from '../../types/enums/FlowType'
import CompanyStructure from './CompanyStructure'
import LimitedCompanyForm from './LimitedCompanyForm'
import SoleTraderForm from './SoleTraderForm'
import { VirtualPage } from '../../types/enums/VirtualPage'
import useVirtualPageView from '../../custom-hooks/useVirtualPageView'
import Faq from '../../components/faq/Faq'
import useTrackEvent from '../../custom-hooks/useTrackEvent'
import { EventTypes } from '../../types/enums/TrackEventType'
import { CompanyTypeContext } from './CompanyTypeContext'

export type CompanyInformationForm = {
  companyType?: CompanyType
  requester?: Person
  company?: Company
  soleTraderEmail?: string
}

const StyledLink = styled(Link)`
  &.MuiLink-root {
    color: ${theme.palette.common.textGrey900};
  }
`

const flowTypeMap = {
  [CompanyType.LimitedCompany]: FlowType.LimitedCompany,
  [CompanyType.GovernmentEntity]: FlowType.LimitedCompany,
  [CompanyType.SoleTrader]: FlowType.SoleTrader
}

const formComponents = {
  limitedcompany: LimitedCompanyForm,
  soletrader: SoleTraderForm
}

const CompanyInformationPage = (data: OnboardingJourney) => {
  const trackPageView = useVirtualPageView()
  const trackEvent = useTrackEvent()
  const [flowType, setFlowType] = useState<FlowType | undefined>(data.flowType || FlowType.LimitedCompany)
  const [companyType, setCompanyType] = useState<string | undefined>(CompanyType.LimitedCompany)
  const [displayHeaderContent, setDisplayHeaderContent] = useState(true)
  const isEngagementThresholdMet = useRef<boolean>(false)
  const [companyStructureShouldBeDisplayed, setCompanyStructureShouldBeDisplayed] = useState<boolean>(!data.flowType)

  useEffect(() => {
    setFlowType(data.flowType)
    setCompanyType(undefined)
    setCompanyStructureShouldBeDisplayed(
      !data.flowType ||
        (data.flowType.toLowerCase() == FlowType.LimitedCompany.toLowerCase() && !data.company?.kriyaCompanyIdentifier)
    )
  }, [data.flowType])

  useEffect(() => {
    trackPageView(VirtualPage.COMPANY_SELECTION)

    if (!isEngagementThresholdMet.current) {
      const timer = setTimeout(() => {
        isEngagementThresholdMet.current = true
        trackEvent(EventTypes.CompanyInformation.ENGAGEMENT_MET)
      }, 5000)

      return () => clearTimeout(timer)
    }
  }, [])

  function setFlowTypeFromCompanyType(value: string) {
    setCompanyType(value)
    setFlowType(flowTypeMap[value as CompanyType])
  }

  const flowTypeKey = flowType?.toLowerCase() as keyof typeof formComponents
  const FormToRender = formComponents[flowTypeKey]

  return (
    <>
      {data.merchant.logoUri ? <Logo logoUri={data.merchant.logoUri}></Logo> : <br />}
      <Box
        sx={{
          marginTop: { xs: theme.spacing(24), sm: theme.spacing(32) }
        }}
      >
        {displayHeaderContent && (
          <Content
            sx={{
              marginBottom: theme.spacing(10)
            }}
          >
            <Typography variant="h2" data-cy="title">
              Welcome to Kriya
            </Typography>

            <ContentGroup style={{ rowGap: theme.spacing(8) }}>
              <Typography variant="body2" data-cy="content">
                To unlock your spending limit, we need to run a few checks.
              </Typography>

              <Typography variant="body2" data-cy="eligibility-content">
                First, we will need the following information to verify your eligibility.
              </Typography>
            </ContentGroup>
          </Content>
        )}
        <CompanyTypeContext.Provider value={{ companyType, setCompanyType: setFlowTypeFromCompanyType }}>
          <Box>
            {companyStructureShouldBeDisplayed && (
              <Box
                sx={{
                  marginTop: theme.spacing(32),
                  marginBottom: !companyType ? theme.spacing(22) : theme.spacing(12)
                }}
              >
                <CompanyStructure
                  merchantName={data.merchant.name}
                  redirectUrl={data.abandonedUrl}
                  flowType={data.flowType}
                />
              </Box>
            )}

            {FormToRender && (
              <FormToRender onboardingJourney={data} setDisplayHeaderContent={setDisplayHeaderContent} />
            )}

            <div style={{ marginTop: theme.spacing(20), marginBottom: theme.spacing(31) }}>
              <Typography variant="helperText2" sx={{ color: theme.palette.common.textGrey900 }}>
                We're committed to handling your data with the highest standards of privacy. For more details, please
                review our{' '}
                <StyledLink
                  href="https://www.kriya.co/privacy-policy"
                  data-cy="privacy-policy-link"
                  aria-label="Review Privacy Policy"
                  target="_blank"
                >
                  Privacy Policy
                </StyledLink>
              </Typography>
            </div>

            <Faq marketplaceName={data.merchant.name} />
          </Box>
        </CompanyTypeContext.Provider>
      </Box>
    </>
  )
}

export default CompanyInformationPage
