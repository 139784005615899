import React from 'react'
import { StyledButtonsContainer } from './layout/Container'
import ActionButton from './ActionButton'
import ReturnToMartketplaceDialog from './returnToMarketplace'
import { SxProps } from '@mui/material/styles'
import { OnboardingJourneySource } from '../types/enums/OnboardingJourneySource'

export type PanelWithDefaultButtonsProps = {
  merchantName: string
  isDisabled: boolean
  isLoading: boolean
  redirectUrl?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  source?: string
  sx?: SxProps
  label?: string
}

const PanelWithDefaultButtons = ({
  merchantName,
  redirectUrl,
  isDisabled,
  isLoading,
  onClick,
  source,
  sx,
  label
}: PanelWithDefaultButtonsProps) => {
  return (
    <StyledButtonsContainer sx={sx}>
      <ActionButton id="continue-button" disabled={isDisabled} onClick={onClick} loading={isLoading}>
        {label ?? 'Continue'}
      </ActionButton>
      {source !== OnboardingJourneySource.MarketplacePortal && redirectUrl && (
        <ReturnToMartketplaceDialog
          marketplace={merchantName}
          disabled={isLoading}
          redirectUrl={redirectUrl}
        ></ReturnToMartketplaceDialog>
      )}
    </StyledButtonsContainer>
  )
}

export default PanelWithDefaultButtons
