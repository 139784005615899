import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { CompanyType } from '../../types/enums/CompanyType'
import theme from '../../assets/theme'
import { useCompanyTypeContext } from './CompanyTypeContext'
import PanelWithDefaultButtons from '../../components/PanelWithDefaultButtons'
import { FlowType } from '../../types/enums/FlowType'

export type CompanyStructureProps = {
  merchantName: string
  redirectUrl: string
  flowType?: FlowType
}

const CompanyStructure = ({ merchantName, redirectUrl, flowType }: CompanyStructureProps) => {
  const { companyType, setCompanyType } = useCompanyTypeContext()

  const onChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setCompanyType(value)
  }
  return (
    <>
      <Box sx={{ marginBottom: theme.spacing(10) }}>
        <Typography data-cy="header" variant="caption">
          What is your business structure?
        </Typography>
      </Box>
      <Box>
        <RadioGroup
          id="company-structure-radio-buttons-group"
          aria-labelledby="company-structure-radio-buttons-group"
          name="company-structure-radio-buttons-group"
          value={companyType}
          onChange={onChange}
        >
          <FormControlLabel
            value={CompanyType.LimitedCompany}
            control={<Radio id="limited-company-radio" />}
            label="Limited company"
          />
          {!flowType && (
            <FormControlLabel
              value={CompanyType.SoleTrader}
              control={<Radio id="sole-trader-radio" />}
              label="Sole trader"
            />
          )}
          <FormControlLabel
            value={CompanyType.GovernmentEntity}
            control={<Radio id="government-entity-radio" />}
            label="Government entity"
          />
        </RadioGroup>
      </Box>
      {!companyType && (
        <PanelWithDefaultButtons
          merchantName={merchantName}
          redirectUrl={redirectUrl}
          isLoading={false}
          isDisabled={true}
          sx={{ pt: theme.spacing(22) }}
        />
      )}
    </>
  )
}

export default CompanyStructure
