import { InvalidCompanyStatus, RiskDecisionStatus } from '../types/enums/RiskDecisionStatus'
import { retry } from '../api/apiBaseUrl'
import { fetchOnboardingJourneyDetails, fetchOnboardingJourneyKey } from './useOnboardingJourneyQuery'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { AxiosError } from 'axios'
import { EligibilityCheck } from '../types/EligibilityCheck'
import { EligibilityStatus } from '../types/enums/EligibilityStatus'
import { OnboardingJourneyRedirection } from '../pages/error/OnboardingJourneyRedirection'
import { apiClient } from '../api/apiClient'
import { useState } from 'react'

const startEligibilityCheck = async (onboardingJourneyId: string): Promise<EligibilityCheck> => {
  const url = `/${onboardingJourneyId}/eligibilitycheck`
  const response = await apiClient.post<EligibilityCheck>(url)
  return response.data
}

const fetchEligibilityStatusKey = 'fetch-eligibility-status'

const fetchEligibilityStatus = async (onboardingJourneyId: string): Promise<EligibilityCheck> => {
  const url = `/${onboardingJourneyId}/eligibilitycheckstatus`
  const response = await apiClient.get<EligibilityCheck>(url)
  return response.data
}

const eligibilityTimeout = 150

export const useEligibilityInfo = ({
  onboardingJourneyId,
  onSuccess,
  onError,
  timeout = eligibilityTimeout
}: {
  onboardingJourneyId: string
  onSuccess?: () => void
  onError?: (error: AxiosError) => void
  timeout?: number
}) => {
  const { merchantId, merchantCompanyIdentifier } = useParams()
  const [start, setStart] = useState(false)
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [notifyTimeout, setNotifyTimeout] = useState(false)

  const onErrorHandler = (error: AxiosError) => {
    setStart(false)
    if (onError) {
      onError(error)
    } else {
      navigate('/error')
    }
  }

  const stopPolling = () => {
    setStart(false)

    if (timer) {
      clearTimeout(timer)
    }
  }

  const { mutate } = useMutation(startEligibilityCheck, {
    onError: (error: AxiosError) => {
      onErrorHandler(error)
    },
    onSuccess: () => {
      setStart(true)
      const _timer = setTimeout(() => {
        setNotifyTimeout(true)
        setStart(false)
        return
      }, timeout * 1000)

      setTimer(_timer)
    }
  })

  useQuery<EligibilityCheck, AxiosError>(
    [fetchEligibilityStatusKey, onboardingJourneyId],
    () => fetchEligibilityStatus(onboardingJourneyId),
    {
      onSuccess: async (data: EligibilityCheck) => {
        if (data.eligibilityCheckStatus === EligibilityStatus.Completed) {
          try {
            const response = await queryClient.fetchQuery(
              [fetchOnboardingJourneyKey, merchantId, merchantCompanyIdentifier],
              () => fetchOnboardingJourneyDetails(merchantId!, merchantCompanyIdentifier!)
            )
            stopPolling()

            const riskDecisionStatus = response.riskDecision.status.toLowerCase()
            if (riskDecisionStatus === RiskDecisionStatus.Approved) {
              if (onSuccess) {
                onSuccess()
              }
            } else {
              if (InvalidCompanyStatus.includes(riskDecisionStatus as RiskDecisionStatus)) {
                const path = OnboardingJourneyRedirection(riskDecisionStatus as RiskDecisionStatus)

                if (path !== undefined) {
                  return navigate(`/${merchantId!}/${merchantCompanyIdentifier!}/${path}`, {
                    replace: true
                  })
                }
              }
            }
          } catch {
            stopPolling()
            navigate('/error')
          }
        }
      },
      onError: (error: AxiosError) => {
        onErrorHandler(error)
      },
      enabled: start,
      refetchInterval: start ? 3000 : false,
      refetchOnWindowFocus: false,
      retry
    }
  )

  return { mutate, notifyTimeout, start }
}
